<template>
  <router-link
    v-if="!isAbsoluteURL"
    :to="localizedUrl + url"
    :class="className"
    :target="target"
  >
    <slot />
  </router-link>
  <a v-else :href="url" :class="className" :target="target"><slot /></a>
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { ReadyHook } from 'vue-asyncready'

export default {
  props: ['item', 'pagePosition', 'url', 'target', 'text', 'className'],
  mixins: [ReadyHook],
  name: 'localized-url',
  data() {
    return {}
  },
  computed: {
    localizedUrl() {
      return currentStoreView().url || ''
    },
    isAbsoluteURL() {
      return (
        this.url.indexOf('http://') === 0 ||
        this.url.indexOf('https://') === 0 ||
        this.url.indexOf('www.') === 0
      )
    }
  }
}
</script>
