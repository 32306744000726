<template>
  <section>
    <div class="interactiveSlider">
      <div class="slide" ref="slides" v-for="(slide, i) in items" :key="i" :class="{'-active': i === currentSlide}">
        <localized-url v-if="slide.ctaLink" :url="slide.ctaLink">
          <lazy-picture :item="slide" preserve-ratio="true"></lazy-picture>
        </localized-url>
        <lazy-picture v-else :item="slide" preserve-ratio="true"></lazy-picture>
      </div>
      <button v-if="isMobile" type="button" class="btn-autoPlay" :class="{'-paused': pauseSlider}" :aria-label="pauseSlider ? 'Play' : 'Pause'" @click="toggleSlider"></button>
      <strong v-if="isMobile" class="a11y-sr-only" aria-live="polite" v-html="$t('(current slide)') + ' ' + currentSlide"></strong>
    </div>
  </section>
</template>
<script>
import { ReadyHook } from 'vue-asyncready'
import LazyPicture from './utils/LazyPicture'
import LocalizedUrl from './utils/LocalizedUrl'

export default {
  props: ['item', 'items', 'pagePosition', 'siblings-components'],
  name: 'interactive_highlight_slider',
  mixins: [ReadyHook],
  components: {
    LazyPicture,
    LocalizedUrl
  },
  data () {
    return {
      pauseSlider: false,
      intervalSlider: null,
      currentSlide: 0
    }
  },
  computed: {
    isMobile() {
      return this.$store.state['zenith-ui'].menu.mobile
    }
  },
  methods: {
    toggleSlider () {
      if (this.pauseSlider) {
        this.initSlider()
      } else {
        clearTimeout(this.intervalSlider)
      }
      this.pauseSlider = !this.pauseSlider
    },
    initSlider () {
      this.intervalSlider = setInterval(() => {
        if (this.currentSlide < this.items.length - 1) {
          this.currentSlide++
        } else {
          this.currentSlide = 0
        }
      }, 4000)
    }
  },
  mounted () {
    if (this.isMobile) {
      this.initSlider()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/core";

.interactiveSlider {
  position: relative;
  display: flex;
  height: 60rem;
  width: 100%;

  @include bp(max md) {
    height: 100vw;
  }

  .slide {
    position: relative;
    height: 100%;
    flex-grow: 1;
    max-width: 100%;
    transition: all 1s ease;

    /deep/ img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:hover {
      flex-grow: 2;
    }

    @include bp(max md) {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      top: 0;
      flex-grow: 1;
      opacity: 0;
      transition: all 0.4s ease-in-out;

      &.-active {
        opacity: 1;
      }
    }
  }

  .btn-autoPlay {
    display: none;
    position: absolute;
    bottom: 2rem;
    right: 2rem;

    @include bp(max md) {
      display: block;
    }
  }
}
</style>
